import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { MenuSelectorField, ModelAutocompleteField, ModelMenuSelectorField, OptionSelectorField } from '@front/squirtle'
import UserUtils from '@front/volcanion/utils/user'

const Row1 = ({ resetRecivers, ...props }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <OptionSelectorField
        name='audience'
        options={[
          { label: I18n.t('driver.label.all'), value: 'all' },
          { label: I18n.t('driver.label', { count: 1 }), value: 'custom_drivers' },
        ]}
        afterChange={resetRecivers}
      />
    </Grid>
  </Grid>

const Row2 = ({ audience, disableOptions, ...props }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs hidden={audience !== 'all'}>
      <ModelMenuSelectorField
        name='vehicle_options'
        label={I18n.t('options.vehicleoption.label', { count: -1 })}
        model_name='vehicleoption'
        labelKeys={['name_translated']}
        searchMode='search'
        loadOnMount
        multiple
        disabled={disableOptions}
      />
    </Grid>
    <Grid item xs hidden={audience !== 'all'}>
      <ModelMenuSelectorField
        name='driver_options'
        label={I18n.t('options.useroption.label', { count: -1 })}
        model_name='useroption'
        labelKeys={['name_translated']}
        searchMode='search'
        loadOnMount
        multiple
        disabled={disableOptions}
      />
    </Grid>
    <Grid item xs hidden={audience !== 'all'}>
      <MenuSelectorField
        label={I18n.t('vehicle.pairing_type')}
        name='is_paired'
        noneLabel={I18n.t('all')}
        options={[
          { label: I18n.t('broadcast.paired'), value: true },
          { label: I18n.t('broadcast.not_paired'), value: false }
        ]}
      />
    </Grid>
    <Grid item xs hidden={audience === 'all'}>
      <ModelAutocompleteField
        name={'drivers'}
        model_name={'user'}
        searchMode='search'
        searchQuery={'='}
        autocompleteMutator={q => ({
          or: [
            { info: { user_client_id: { startsWith: _.split(q, ' ') } } },
            { info: { first_name: { startsWith: _.split(q, ' ') } } },
            { info: { last_name: { startsWith: _.split(q, ' ') } } }]
        })}
        labelKeys={['label']}
        multiple
        loadOnFocus
        config={{
          forced_filter: { driverinfo: { '!': null } },
          populate: ['info', 'customerinfo'],
          mutator: user => ({
            label: UserUtils.getFormatedName({ ...user?.info, designation: user?.customerinfo?.name }, { prefix: user?.info?.user_client_id }),
            ...user
          })
        }}
        required={audience !== 'all'}
        debounceTime={400}
      />
    </Grid>
  </Grid>

const AudienceSection = (props) => {
  const { disableOptions } = props || {}
  return (
    <>
      <StepLabel>
        <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 'h6.fontSize', color: 'text.title' }}>
          <Typography variant='h5'>{I18n.t('message.recipients')}</Typography>
        </Box>
      </StepLabel>
      <StepContent >
        <Box sx={{ boxShadow: 6, borderRadius: 1, p: 2 }}>
          {!disableOptions && <Row1 {...props} />}
          <Row2 {...props} />
        </Box>
      </StepContent>
    </>
  )
}

export default React.memo(AudienceSection)
