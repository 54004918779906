import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { OptionSelectorField } from '@front/squirtle'

const Row1 = props =>
  <Grid container item xs={12} spacing={2}>
    <Grid item xs>
      <OptionSelectorField
        required
        name='type'
        options={[
          { label: I18n.t('message.alert_real_time'), value: 'alert' },
          { label: I18n.t('message.memorized'), value: 'message' }
        ]}
      />
    </Grid>
  </Grid>


const TypeSection = props => <>
  <StepLabel>
    <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 'h6.fontSize', color: 'text.title' }}>
      <Typography variant='h6'>{I18n.t('type.label', { count: 1 })}</Typography>
    </Box>
  </StepLabel>
  <StepContent >
    <Grid container rowSpacing={2} >
      <Row1 />
    </Grid>
  </StepContent>
</>

export default TypeSection
