import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { MenuSelectorField, ModelMenuSelectorField } from '@front/squirtle'

const Row1 = (props) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <ModelMenuSelectorField
        name='vehicle_options'
        label={I18n.t('options.vehicleoption.label', { count: -1 })}
        model_name='vehicleoption'
        labelKeys={['name_translated']}
        searchMode='search'
        loadOnMount
        multiple
        disabled={true}
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name='driver_options'
        label={I18n.t('options.useroption.label', { count: -1 })}
        model_name='useroption'
        labelKeys={['name_translated']}
        searchMode='search'
        loadOnMount
        multiple
        disabled={true}
      />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        label={I18n.t('vehicle.pairing_type')}
        name='is_paired'
        noneLabel={I18n.t('all')}
        options={[
          { label: I18n.t('broadcast.paired'), value: true },
          { label: I18n.t('broadcast.not_paired'), value: false }
        ]}
      />
    </Grid>
  </Grid>

const AudienceSection = (props) => {
  return (
    <>
      <StepLabel>
        <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 'h6.fontSize', color: 'text.title' }}>
          <Typography variant='h6'>{I18n.t('message.recipients')}</Typography>
        </Box>
      </StepLabel>
      <StepContent >
        <Row1 {...props} />
      </StepContent>
    </>
  )
}

export default AudienceSection
