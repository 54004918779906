import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { ModelAutocompleteField, SingleOptionSelectorField } from '@front/squirtle'

const Row1 = (props) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={4}>
      <ModelAutocompleteField
        name='job'
        label={I18n.t('order.id.alter')}
        model_name={'order'}
        searchMode='search'
        searchQuery={'contains'}
        autocompleteKey={'order_client_id'}
        labelKeys={['order_client_id']}
        selectKeys={['job']}
        selectSingleOption
        selectFirst={false}
        required
        config={{
          forced_filter: { status: ['created', 'standby'] }
        }}
      />
    </Grid>
    <Grid item xs={2}>
      <SingleOptionSelectorField
        name='important'
        labelPlacement='start'
        optionLabel={I18n.t('broadcast.important')}
        valueType='checkbox'
      />
    </Grid>
  </Grid>


const JobSection = props => <>
  <StepLabel>
    <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 'h6.fontSize', color: 'text.title' }}>
      <Typography variant='h5'>{I18n.t('broadcast.model.job')}</Typography>
    </Box>
  </StepLabel>
  <StepContent >
    <Box sx={{ boxShadow: 6, borderRadius: 1, p: 2 }}>
      <Row1 {...props} />
    </Box>
  </StepContent>
</>

export default JobSection
