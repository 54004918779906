import React, { useCallback, useEffect } from 'react'
import { hooks } from '@front/volcanion'

import Component from './component'
import Callbacks from './callbacks'

const JobContainer = (props) => {
  const setFormValues = hooks.useFormChange()
  const [job] = hooks.useFieldValues(['job'])
  const [job_object] = hooks.useModel('job', [job], { populate: ['first_step.order.driver_opts', 'first_step.order.vehicle_opts'], single: true })
  const order = job_object?.first_step?.order
  console.log('pauline test', { job })
  const order_client_id = order?.order_client_id

  const afterChangeJob = useCallback(Callbacks.afterChangeJobHandler(setFormValues), [setFormValues])

  useEffect(() => {
    afterChangeJob(order)
  }, [order?.order_id])


  const mergedProps = {
    order_client_id
  }

  return (
    <Component {...mergedProps} />
  )
}

export default JobContainer
