import React from 'react'
import { Grid, Step } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import TypeSection from './Section/Type'
import AudienceSection from './Section/Audience'
import JobSection from './Section/Job'
import JobInfoSection from './Section/JobInfo'

const NotifyDrivers = ({ job_id }) => <>
  <Grid container item >
    <Grid item xs={12}>
      <GridStepper groupsCount={[4]} activeStep={0} orientation="vertical">
        <Step> <TypeSection /> </Step>
        <Step><JobSection job_id={job_id} /></Step>
        <Step> <AudienceSection /> </Step>
        <Step><JobInfoSection /></Step>
      </GridStepper>
    </Grid>
  </Grid>
</>



export default NotifyDrivers
