class Callbacks {
  static afterChangeModelHandler(setFormValues) {
    return function afterChangeModel(value) {
      if (value === 'standard')
        setFormValues([
          { field: 'vehicle_options', value: undefined },
          { field: 'driver_options', value: undefined },
          { field: 'is_paired', value: value === 'all' ? true : undefined },
          { field: 'drivers', value: undefined },
          { field: 'job', value: undefined }
        ])
    }
  }
}

export default Callbacks
