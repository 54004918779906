import React, { useEffect } from 'react'
import { hooks } from '@front/volcanion'

import Component from './component'

const Container = (props) => {
  const { job_id } = props || {}
  const setFormValues = hooks.useFormChange()

  const mergedProps = {
    job_id
  }

  useEffect(() => setFormValues([{ field: 'job', value: job_id }]), [job_id])
  return (
    <Component {...mergedProps} />
  )
}

export default Container
