import React from 'react'

import { hooks } from '@front/volcanion'

import Component from './component'

const JobInfoContainer = (props) => {
  const [job_id] = hooks.useFieldValues(['job'])
  const [job] = hooks.useModel('job', [job_id], {
    populate: [
      'first_step.order.payment_type.name_trkey',
      'first_step.order.do.customerinfo',
      'first_step.order.service.contract.company',
      'first_step.order.source',
      'first_step.order.destination',
      'first_step.order.commercial_package'
    ],
    single: true
  })

  const order = job?.first_step?.order

  const mergedProps = {
    order
  }

  return (
    <Component {...mergedProps} />
  )
}

export default JobInfoContainer
