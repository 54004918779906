class Callbacks {
  static resetReciversHandler(setFormValues) {
    return function resetRecivers(value) {
      setFormValues([
        { field: 'vehicle_options', value: undefined },
        { field: 'driver_options', value: undefined },
        { field: 'is_paired', value: value === 'all' ? true : undefined },
        { field: 'drivers', value: undefined }
      ])
    }
  }
}

export default Callbacks
