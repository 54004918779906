class Callbacks {
  static afterChangeJobHandler(setFormValues) {
    return function afterChangeJob(order) {
      if (!!order) {
        const driver_options = _.map(order?.driver_opts, 'user_option_id')
        const vehicle_options = _.map(order?.vehicle_opts, 'vehicle_option_id')
        setFormValues([
          { field: 'vehicle_options', value: vehicle_options },
          { field: 'driver_options', value: driver_options }
        ])
      }
    }
  }
}

export default Callbacks
