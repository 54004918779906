import React from 'react'
import { I18n } from '@front/volcanion/'
import { TimeUtils } from '@front/volcanion/utils'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { TextComponent } from '@front/squirtle'

const Row1 = (props) => {
  const { title } = props || {}

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={12}>
        <TextComponent
          label={I18n.t('title.label', { count: 1 })}
          value={title}
          disabled
        />
      </Grid>
    </Grid>
  )
}

const Row2 = (props) => {
  const { order } = props || {}

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item >
        <TextComponent
          label={I18n.t('commercialpackage.label', { count: 1 })}
          value={order?.commercial_package?.name}
          disabled
        />
      </Grid>
      <Grid item >
        <TextComponent
          label={I18n.t('date.label.full')}
          value={TimeUtils.formatDateBackToFront(order?.requestedAt)}
          disabled
        />
      </Grid>
      <Grid item  >
        <TextComponent
          label={I18n.t('company.label', { count: 1 })}
          value={order?.service?.contract?.company?.name}
          disabled
        />
      </Grid>
      <Grid item  >
        <TextComponent
          label={I18n.t('address.start')}
          value={order?.source?.postal_code}
          disabled
        />
      </Grid>
      <Grid item  >
        <TextComponent
          label={I18n.t('address.destination')}
          value={order?.destination?.postal_code}
          disabled
        />
      </Grid>
      <Grid item  >
        <TextComponent
          label={I18n.t('payment.label', { count: 1 })}
          value={order?.payment_type?.name_translated}
          disabled
        />
      </Grid>
    </Grid>
  )
}


const JobInfoSection = (props) => <>
  <StepLabel>
    <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 'h6.fontSize', color: 'text.title' }}>
      <Typography variant='h6'>{I18n.t('message.label', { count: 1 })}</Typography>
    </Box>
  </StepLabel>
  <StepContent >
    <Grid container rowSpacing={4} >
      <Row1 {...props} />
      <Row2 {...props} />
    </Grid>
  </StepContent>
</>

export default JobInfoSection
