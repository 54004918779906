import React, { useCallback, useEffect } from 'react'
import { hooks } from '@front/volcanion'

import Component from './component'
import Callbacks from './callbacks'

const JobContainer = (props) => {
  const setFormValues = hooks.useFormChange()
  const [job_id, important] = hooks.useFieldValues(['job', 'important'])
  const [job] = hooks.useModel('job', [job_id], { populate: ['first_step.order.driver_opts', 'first_step.order.vehicle_opts', 'first_step.order.service.contract.company'], single: true })
  const order = job?.first_step?.order

  console.log('pauline test 0', { order, job_id, important })

  const afterChangeJob = useCallback(Callbacks.afterChangeJobHandler(setFormValues), [setFormValues])

  useEffect(() => {
    afterChangeJob(order, important)
  }, [order?.order_id, important])


  const mergedProps = {
  }

  return (
    <Component {...mergedProps} />
  )
}

export default JobContainer
