import React, { useMemo, useCallback } from 'react'

import { hooks } from '@front/volcanion'
import { TimeUtils } from '@front/volcanion/utils'

import Component from './component'

const JobInfoContainer = (props) => {
  const [job] = hooks.useFieldValues(['job'])
  const [order] = hooks.useModelSearch('order', 'get', {
    forced_filter: { job }, populate: [
      'payment_type.name_trkey',
      'do.customerinfo',
      'service.contract.company',
      'source',
      'destination',
      'commercial_package'
    ],
    validate_filter: useCallback(() => !!job, [job]),
    watchers: [job],
    limit: 1,
    single:
      true,
    sort: ['requestedAt ASC']
  })

  const title = useMemo(() => {
    if (!!order) {
      const name = order?.service?.contract?.company?.name
      const requestedAt = TimeUtils.formatDateBackToFront(order?.requestedAt)
      const source_cp = order?.source?.postal_code

      return _.join(['Course', _.compact([name, requestedAt, source_cp]).join(' - ')], ' ')
    }
  }, [order?.order_id])

  const mergedProps = {
    title,
    order
  }

  return (
    <Component {...mergedProps} />
  )
}

export default JobInfoContainer
