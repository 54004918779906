import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { OptionSelectorField, MenuSelectorField } from '@front/squirtle'

const Row1 = props =>
  <Grid container item xs={12} spacing={2}>
    <Grid item xs>
      <OptionSelectorField
        required
        name='type'
        options={[
          { label: I18n.t('message.alert_real_time'), value: 'alert' },
          { label: I18n.t('message.memorized'), value: 'message' }
        ]}
      />
    </Grid>
  </Grid>

const Row2 = (props) => {
  const { afterChangeModel } = props || {}

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={2}>
        <MenuSelectorField
          label={I18n.t('broadcast.model.label')}
          name='model'
          allowNone={false}
          selectFirst
          options={[
            { label: I18n.t('broadcast.model.standard'), value: 'standard' },
            { label: I18n.t('broadcast.model.job'), value: 'job' }
          ]}
          afterChange={afterChangeModel}
        />
      </Grid>
    </Grid>
  )
}


const PrimarySection = props => <>
  <StepLabel>
    <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 'h6.fontSize', color: 'text.title' }}>
      <Typography variant='h5'>{I18n.t('type.label', { count: 1 })}</Typography>
    </Box>
  </StepLabel>
  <StepContent >
    <Box sx={{ boxShadow: 6, borderRadius: 1, p: 2 }}>
      <Grid container rowSpacing={2} >
        <Row1 />
        <Row2 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
