import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const setFormValues = hooks.useFormChange()
  const [model, audience] = hooks.useFieldValues(['model', 'audience'])

  const disableOptions = model === 'job'

  const resetRecivers = useCallback(Callbacks.resetReciversHandler(setFormValues), [setFormValues])

  const mergedProps = {
    audience,
    resetRecivers,
    disableOptions
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
