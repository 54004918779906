import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { TextField } from '@front/squirtle'

const Row1 = ({ count_title, ...props }) =>
  <Grid container item xs={12} spacing={2}>
    <Grid item xs>
      <TextField
        label={I18n.t('title.limit', { min: count_title, max: 250 })}
        name='title'
        inputProps={{ maxLength: 250 }}
        required
      />
    </Grid>
  </Grid>

const Row2 = ({ count_body, ...props }) =>
  <Grid container item xs={12} spacing={2}>
    <Grid item xs>
      <TextField
        label={I18n.t('message.limit', { min: count_body, max: 250 })}
        name='body'
        inputProps={{ maxLength: 250 }}
      />
    </Grid>
  </Grid>

const PrimarySection = ({ count_title, count_body, ...props }) => <>
  <StepLabel>
    <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 'h6.fontSize', color: 'text.title' }}>
      <Typography variant='h5'>{I18n.t('message.label', { count: 1 })}</Typography>
    </Box>
  </StepLabel>
  <StepContent >
    <Box rowSpacing={3} sx={{ p: 2, boxShadow: 3, borderRadius: 1 }}>
      <Grid container rowSpacing={2}>
        <Row1 count_title={count_title} />
        <Row2 count_body={count_body} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
