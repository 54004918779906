import React, { useCallback } from 'react'

import { Loader } from '@front/squirtle'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => ({ goBackPath }) => {
  const form_name = hooks.useFormName()
  const navigate = hooks.useNavigate()
  const isReady = hooks.useFormStatus('isReady')
  const requestSubmit = hooks.useGlobalFormFunction(form_name, 'requestSubmit')
  const goBack = useCallback(Callbacks.goBackHandler(goBackPath, navigate), [goBackPath, navigate])
  const [model] = hooks.useFieldValues(['model'])

  const mergedProps = {
    goBack,
    requestSubmit,
    model
  }

  return (
    <Loader isLoading={!isReady}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer
