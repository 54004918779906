import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { SingleOptionSelectorField, TextComponent } from '@front/squirtle'

const Row1 = ({ order_client_id, ...props }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={4}>
      <TextComponent
        label={I18n.t('order.id.alter')}
        value={order_client_id}
        disabled
      />
    </Grid>
    <Grid item xs={2}>
      <SingleOptionSelectorField
        name='important'
        labelPlacement='start'
        optionLabel={I18n.t('broadcast.important')}
        valueType='checkbox'
      />
    </Grid>
  </Grid>


const JobSection = props => <>
  <StepLabel>
    <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 'h6.fontSize', color: 'text.title' }}>
      <Typography variant='h6'>{I18n.t('broadcast.model.job')}</Typography>
    </Box>
  </StepLabel>
  <StepContent >
    <Row1 {...props} />
  </StepContent>
</>

export default JobSection
