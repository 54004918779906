import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const setFormValues = hooks.useFormChange()

  const afterChangeModel = useCallback(Callbacks.afterChangeModelHandler(setFormValues), [setFormValues])

  const mergedProps = {
    afterChangeModel
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
