
import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Button, Grid, Step } from '@mui/material'
import { GridStepper, ConfirmationDialog, SubmitButton } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'

import TypeSection from './Section/Type'
import AudienceSection from './Section/Audience'
import MessageSection from './Section/Message'
import JobSection from './Section/Job'
import JobInfoSection from './Section/JobInfo'

const Send = ({ goBack, requestSubmit, model }) => <>
  <ConfirmationDialog name='warning_comfirmation_audience' onConfirm={() => requestSubmit({ forceSubmit: true })} />
  <Box className='safearea'>
    <Grid container justifyContent='center'>
      <Grid container item xs={9} rowSpacing={3}>
        <Grid item xs={12} >
          <FormHeader enableActions={false} />
        </Grid>
        <Grid item xs={12}>
          <GridStepper groupsCount={[4]} activeStep={0} orientation="vertical" sx={{ mt: 3 }}>
            <Step> <TypeSection /> </Step>
            {model === 'job' && <Step><JobSection /></Step>}
            <Step> <AudienceSection /> </Step>
            {
              model === 'job' ? <Step><JobInfoSection /></Step>
                : <Step> <MessageSection /> </Step>
            }
          </GridStepper>
        </Grid>
        <Grid container item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ p: 1 }}>
              <Button variant='contained' onClick={goBack} >
                {I18n.t('action.back')}
              </Button>
            </Box>
            <Box sx={{ p: 1 }}>
              <SubmitButton variant='contained' label={I18n.t('action.send')} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  </Box>
</>



export default React.memo(Send)
