import { TimeUtils } from '@front/volcanion/utils'
import { I18n } from '@front/volcanion'
class Callbacks {
  static afterChangeJobHandler(setFormValues) {
    return function afterChangeJob(order, important) {
      console.log('pauline afterChange', { order })
      if (!!order) {
        const driver_options = _.map(order?.driver_opts, 'user_option_id')
        const vehicle_options = _.map(order?.vehicle_opts, 'vehicle_option_id')

        const important_trad = !!important ? _.upperCase(I18n.t('broadcast.important')) : ''
        const name = order?.service?.contract?.company?.name
        const requestedAt = TimeUtils.formatDateBackToFront(order?.requestedAt)
        const source_cp = order?.source?.postal_code

        const title = _.join(_.compact([important_trad, _.upperCase(I18n.t('order.label', { count: 1 })), _.compact([name, requestedAt, source_cp]).join(' - ')]), ' ')

        console.log('pauline value', { title })

        setFormValues([
          { field: 'vehicle_options', value: vehicle_options },
          { field: 'driver_options', value: driver_options },
          { field: 'title', value: title }
        ])
      }
      else
        setFormValues([
          { field: 'vehicle_options', value: undefined },
          { field: 'driver_options', value: undefined },
          { field: 'title', value: undefined }
        ])
    }
  }
}

export default Callbacks
